<template>
  <div>
    <app-page-detail page-title="Detail MJO" page-toolbar-title="Detail MJO">

      <template #toolbar>
        <action-button :data-detail="data_detail" @refresh-detail="refreshDetail()"></action-button>
      </template>

      <v-row v-if="!notFound">
        <v-col cols="12" lg="9">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="6" md="6">
                <v-text-field dense disabled v-model="mjo_header.document_no_" placeholder="Doc. No." label="Doc. No." readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="mjo_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2" type="date"></v-text-field>
                <v-select dense disabled v-model="mjo_header.company_id" :items="list_company" item-text="label" item-value="value" placeholder="Company ID" label="Company ID" readonly filled outlined hide-details class="mb-2"></v-select>
                <v-text-field dense disabled v-model="mjo_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col cols="6" md="6">
                <v-select disabled readonly filled :items="$app_options.mjo.priority" dense v-model="mjo_header.priority" placeholder="Prioritas" label="Prioritas" outlined hide-details class="mb-2"></v-select>
                <v-select disabled readonly filled :items="$app_options.mjo.job_type" dense v-model="mjo_header.job_type" placeholder="Tipe Pekerjaan" label="Tipe Pekerjaan" outlined hide-details class="mb-2"></v-select>
                <v-text-field disabled readonly filled type="datetime-local" dense v-model="mjo_header.damage_at" placeholder="Tanggal Kerusakan" label="Tanggal Kerusakan" outlined hide-details class="mb-2"></v-text-field>
              </v-col>
            </v-row>
            <v-textarea disabled readonly filled dense v-model="mjo_header.problem_description" placeholder="Detil Masalah" label="Detil Masalah" outlined hide-details class="mb-2" height="100"></v-textarea>
          </card-expansion>
          <card-expansion title="Fixed Asset" class="mb-3" :isMinimize="true">
            <FixedAssetDetail :mjo_header="mjo_header"></FixedAssetDetail>
          </card-expansion>
          <card-expansion title="Images" class="mb-3" :isMinimize="true">
            <Images :mjo_header="mjo_header"></Images>
          </card-expansion>
          <card-expansion title="Problem Identification" class="mb-3" :isMinimize="true">
            <ProblemIdentification :mjo_header="mjo_header"></ProblemIdentification>
          </card-expansion>
          <card-expansion title="Corrective Acction" class="mb-3" :isMinimize="true">
            <CorrectiveAction2 :mjo_header="mjo_header"></CorrectiveAction2>
          </card-expansion>
          <card-expansion title="Approval" class="mb-3" :isMinimize="true" no-padding>
            <ApprovalList v-bind:data-list="mjo_approval" :hideToolbar="true"></ApprovalList>
          </card-expansion>
        </v-col>
        <v-col cols="12" lg="3">
          <document-info :mjo_header="mjo_header"></document-info>
          <app-comment :document_id="parseInt(mjo_header.id)" :source_document="mjo_header.source_document" :document_no_="mjo_header.document_no_"></app-comment>
        </v-col>
      </v-row>


      <v-alert v-if="notFound" title="tset" type="error">
        <p>Data Not Found!</p>
      </v-alert>
    </app-page-detail>
  </div>
</template>

<script>
import ApprovalList from './components/ApprovalList.vue';
import DocumentInfo from './components/DocumentInfo.vue';
import Images from './components/Images.vue';
import FixedAssetDetail from './components/FixedAssetDetail.vue';
import ProblemIdentification from './components/ProblemIdentification.vue';
import CorrectiveAction2 from './components/CorrectiveAction2.vue';
import ActionButton from './components/ActionButton.vue';

export default {
  components: {
    ApprovalList,
    DocumentInfo,
    Images,
    FixedAssetDetail,
    ProblemIdentification,
    CorrectiveAction2,
    ActionButton,
  },
  data() {
    return {
      id: 0,
      loading: false,
      notFound: false,

      list_company: [
        { label: 'SBU PRODUCTION', value: 'SBUP' },
        { label: 'SBU SALES', value: 'SBUS' },
      ],

      selected_fa: {},
      fa_loading: false,
      fa_list: [],
      data_detail: {},
      mjo_header: {},
      mjo_approval: [],
      mjo_comment: [],
      mjo_pic: [],
      selected_image: 0,
    };
  },
  watch: {
  },

  computed: {
  },
  
  methods: {

    refreshDetail() {
      this.getDetail(true);
    },

    async getDetail(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "mjo/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("mjo/detail", config)
        .then((res) => {
          this.data_detail = res.data.data;
          this.mjo_header = res.data.data.mjo_header;
          this.mjo_approval = res.data.data.mjo_approval;
          this.mjo_comment = res.data.data.mjo_comment;
          this.mjo_pic = res.data.data.mjo_pic;
          this.user = res.data.data.user;

          this.selected_fa.no_ = this.mjo_header.fa_no_;
          this.selected_fa.description = this.mjo_header.fa_description;
          this.selected_fa.description2 = this.mjo_header.fa_description2;
          this.selected_fa.fa_subclass_code = this.mjo_header.fa_fa_subclass_code;
          this.selected_fa.area_code = this.mjo_header.fa_area_code;
          this.selected_fa.location_code = this.mjo_header.fa_location_code;
          this.selected_fa.fa_location_code = this.mjo_header.fa_fa_location_code;
          this.selected_fa.responsibility = this.mjo_header.fa_responsibility;
          this.selected_fa.serial_no_ = this.mjo_header.fa_serial_no_;

          this.showLoadingOverlay(false);

          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (typeof error.response != 'undefined') {
            if (error.response.status == 404) {
              this.notFound = true;
            }
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
};
</script>
