<template>
  <div>
    <v-card outlined elevation="1">
      <v-toolbar dense elevation="0">
        <v-toolbar-title>Perbaikan</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-text-field dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

        <v-btn icon @click="getData(true)" title="Refresh">
          <v-icon>mdi-sync</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table :loading="datatable.loading" :loading-text="datatable.loadingText" :search="search" :headers="datatable.columns" :items="datatable.data" @click:row="rowClick($event)" class="mb-3 my-0">
        <template v-slot:[`item.description`]="{ item }">
          <rightbar title="Description" class="d-inline">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on:click="on">{{ item.description.substring(0, 50) }}{{ (item.description.length > 50) ? '...' : '' }}</span>
            </template>
            <div class="ma-2">
              <v-textarea :value="item.description" outlined dense readonly rows="6" filled></v-textarea>
            </div>
          </rightbar>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    mjo_header: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      id: null,
      loading: null,
      search: "",
      datatable_options: {
        itemsPerPage: 20
      },
      itemsPerPageOption: [20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          // { text: "#", value: "id", class: "text-no-wrap" },
          { text: "Action Date", value: "action_date", class: "text-no-wrap" },
          { text: "Description", value: "description", class: "text-no-wrap" },
          { text: "Created By", value: "full_name", class: "text-no-wrap" },
          { text: "Created Date", value: "created_date", class: "text-no-wrap" },
        ],
        data: [],
      },
    }
  },
  watch: {
    mjo_header(val) {
      if (val) {

        this.id = val.id;

        this.getData(true)
      }
    }
  },
  methods: {
    async getData(refresh) {
      this.datatable.loading = true;
      const uri = "mjo/ca/list";
      if (refresh) this.AxiosStorageRemove("GET", uri);
      await this.$axios
        .get(uri, {
          sessionStorage: !refresh,
          params: {
            mjo_header_id: this.id,
          }
        })
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },

    rowClick($event) {
      console.log($event);
      
      this.showAlert("success", "Row Clicked");
    }
  },
  mounted() {
    this.getData(true);
  }
}
</script>